import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { getMohDefaults } from "../api/moh";
import PageLoader from "../components/PageLoader";

const MoHContext = React.createContext();
const MoHProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [website, setWebsite] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [services, setServices] = useState("");
  const [openTimes, setOpenTimes] = useState("");
  const [tone, setTone] = useState("");

  const mohDefaultsQuery = useQuery(["moh-defaults"], () => getMohDefaults());

  useEffect(() => {
    if (mohDefaultsQuery.data?.data?.data) {
      setWebsite(mohDefaultsQuery.data?.data?.data.website);
      setCompanyName(mohDefaultsQuery.data?.data?.data.company_name);
      setIndustry(mohDefaultsQuery.data?.data?.data.industry);
      setServices(mohDefaultsQuery.data?.data?.data.services);
      setOpenTimes(mohDefaultsQuery.data?.data?.data.open_times);
      setTone(mohDefaultsQuery.data?.data?.data.tone);
      setLoading(false);
    }
  }, [mohDefaultsQuery?.data]);

  const defaultContext = {
    website,
    setWebsite,
    companyName,
    setCompanyName,
    industry,
    setIndustry,
    services,
    setServices,
    openTimes,
    setOpenTimes,
    tone,
    setTone,
  };
  return (
    <MoHContext.Provider value={defaultContext}>
      {mohDefaultsQuery.isFetching || loading ? <PageLoader /> : children}
    </MoHContext.Provider>
  );
};

function useMoH() {
  return useContext(MoHContext);
}

export { MoHProvider, useMoH };
