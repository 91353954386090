import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCallRecordingTranscriptions } from "../../api/transcriptions";
import {
  Container,
  Pagination,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import PageLoader from "../PageLoader";
import CallTranscriptionRow from "./CallTranscriptionRow";
import { useState } from "react";

const CallTranscriptions = ({
  setInfoModal,
  children,
  searchParams,
  setIsSearching,
}) => {
  const [page, setPage] = useState("1");
  const queryClient = useQueryClient();

  const transcriptionsQuery = useQuery(
    ["call-transcriptions", page, searchParams],
    () => {
      return getCallRecordingTranscriptions("", {
        page: page,
        hydrate: "0",
        ...searchParams,
      });
    },
    {
      keepPreviousData: true,
      staleTime: 60000,
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        let interval = false;
        data?.data.data.forEach((i) => {
          if (["SUMMARISING", "TRANSCRIBING"].includes(i?.status.value)) {
            queryClient.invalidateQueries({ queryKey: ["call-recordings"] });
            interval = 30 * 1000;
          }
        });
        return interval;
      },
    },
  );

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  if (transcriptionsQuery.isLoading) return <PageLoader>Loading...</PageLoader>;

  return (
    <Container style={{ marginTop: "20px" }} fluid>
      {transcriptionsQuery?.data.data.data.length > 0 ? (
        <Table basic="very" inverted>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Extension</TableHeaderCell>
              <TableHeaderCell width={1}>Number</TableHeaderCell>
              <TableHeaderCell width={1}>Direction</TableHeaderCell>
              <TableHeaderCell width={2}>Start Time</TableHeaderCell>
              <TableHeaderCell width={2}>End Time</TableHeaderCell>
              <TableHeaderCell width={1}>Duration</TableHeaderCell>
              <TableHeaderCell width={5}>Summary</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell>View</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {transcriptionsQuery?.data.data.data.map((i) => {
              return (
                <CallTranscriptionRow
                  id={i.sqid}
                  key={`transcript-row-${i.sqid}`}
                  status={i.status}
                  hasSummary={i.has_summary}
                  summary={i.summary}
                  sentiment={i.sentiment}
                  direction={i.direction}
                  number={i.tel_number}
                  start={i.start}
                  end={i.end}
                  extension={i.user_ext}
                  duration={i.duration}
                  setInfoModal={setInfoModal}
                />
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div>No transcriptions available.</div>
      )}
      {children}
      <div style={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
        <Pagination
          inverted
          activePage={page}
          totalPages={transcriptionsQuery.data.data.meta.last_page}
          onPageChange={handlePaginationChange}
        />
      </div>
    </Container>
  );
};

export default CallTranscriptions;
