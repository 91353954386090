import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Container,
  Form,
  FormGroup,
  FormInput,
  Header,
} from "semantic-ui-react";
import { gettingToKnowYou, setDefaults } from "../../api/moh";
import { useMoH } from "../../contexts/MoHContext";
import { style } from "../../style";

function GettingToKnowYou() {
  const {
    website,
    setWebsite,
    companyName,
    setCompanyName,
    industry,
    setIndustry,
    services,
    setServices,
    openTimes,
    setOpenTimes,
    tone,
    setTone,
  } = useMoH();

  const navigate = useNavigate();

  const gettingToKnowYouMutation = useMutation({
    mutationFn: () => {
      return gettingToKnowYou({ website: website });
    },
    onSuccess: (res) => {
      setCompanyName(res.data?.data?.company_name ?? "");
      setIndustry(res.data?.data?.industry ?? "");
      setServices(res.data?.data?.services ?? "");
      setOpenTimes(res.data?.data?.open_times ?? "");
      setTone(res.data?.data?.tone ?? "");
    },
    onError: (error) => {
      if (error?.response?.status !== 422) {
        toast.error("Failed to get information from website");
      }
    },
  });

  const defaultsMutation = useMutation({
    mutationFn: () => {
      return setDefaults({
        website,
        company_name: companyName,
        industry,
        services,
        open_times: openTimes,
        tone,
      });
    },
    onSuccess: (res) => {
      navigate(-1);
    },
    onError: (error) => {
      if (error?.response?.status !== 422) {
        toast.error("Failed to save you");
      }
    },
  });

  return (
    <Container style={{ margin: "20px 0px" }}>
      <Breadcrumb>
        <BreadcrumbSection>Admin</BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          <Link to="/admin/moh">MoH</Link>
        </BreadcrumbSection>
        <BreadcrumbDivider icon="right angle" />
        <BreadcrumbSection active>Company Info</BreadcrumbSection>
      </Breadcrumb>

      <Header as="h2">Getting to know you</Header>
      <Form onSubmit={() => gettingToKnowYouMutation.mutate()}>
        <p>
          Get started by entering your website URL below, we'll check this and
          pick out some key information to help generating your music on hold
        </p>

        <FormGroup widths="equal">
          <FormInput
            required
            fluid
            name="website"
            label="Website URL"
            placeholder="Enter your website URL"
            error={
              gettingToKnowYouMutation.error?.response?.data?.errors?.website
            }
            value={website}
            onChange={(e) => {
              setCompanyName("");
              setWebsite(e.target.value);
            }}
          />
        </FormGroup>

        <Button
          loading={gettingToKnowYouMutation.isLoading}
          type="submit"
          style={style.gradientButton}
        >
          {companyName ? "Try Again" : "Submit"}
        </Button>
      </Form>

      {companyName && (
        <Form
          style={{ marginTop: "36px" }}
          onSubmit={() => defaultsMutation.mutate()}
        >
          <p>
            We've managed to identify the below information, you can edit it
            below before saving it anything needs changing.
          </p>

          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="company_name"
              label="Company Name"
              error={
                defaultsMutation.error?.response?.data?.errors?.company_name
              }
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />

            <FormInput
              required
              fluid
              name="industry"
              label="Industry"
              error={defaultsMutation.error?.response?.data?.errors?.industry}
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="services"
              label="Services"
              error={defaultsMutation.error?.response?.data?.errors?.services}
              value={services}
              onChange={(e) => setServices(e.target.value)}
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="open_times"
              label="Open Days & Hours"
              error={defaultsMutation.error?.response?.data?.errors?.open_times}
              value={openTimes}
              onChange={(e) => setOpenTimes(e.target.value)}
            />

            <FormInput
              required
              fluid
              name="tone"
              label="Tone"
              error={defaultsMutation.error?.response?.data?.errors?.tone}
              value={tone}
              onChange={(e) => setTone(e.target.value)}
            />
          </FormGroup>

          <ActionButtonsContainer>
            <Button
              loading={defaultsMutation.isLoading}
              type="submit"
              style={style.purpleGradientButton}
            >
              Save
            </Button>
          </ActionButtonsContainer>
        </Form>
      )}
    </Container>
  );
}

function ActionButtonsContainer({ children }) {
  return (
    <div style={{ marginTop: "48px", marginBottom: "24px" }}>{children}</div>
  );
}

export default GettingToKnowYou;
