import axios from "axios";
import config from "../config";

export function gettingToKnowYou(data) {
  return axios.post(`${config.api_url}/moh/getting-to-know-you`, data);
}

export function listMoh(params) {
  return axios.get(`${config.api_url}/moh`, {
    params: params,
  });
}

export function getMoh(id, params) {
  return axios.get(`${config.api_url}/moh/${id}`, { params: params });
}

export function deleteMoh(id) {
  return axios.delete(`${config.api_url}/moh/${id}`);
}

export function getMohDefaults() {
  return axios.get(`${config.api_url}/moh/defaults`);
}

export function setDefaults(data) {
  return axios.post(`${config.api_url}/moh/defaults`, data);
}

export function generateScript(data) {
  return axios.post(`${config.api_url}/moh/script`, data);
}

export function getSoundOptions() {
  return axios.get(`${config.api_url}/moh/sound-options`);
}

export function getVoicePreviewUrl(voice) {
  return `${config.api_url}/moh/voice-preview/${voice}`;
}

export function getMusicPreviewUrl(music) {
  return `${config.api_url}/moh/music-preview/${music}`;
}

export function generateFile(data) {
  return axios.post(`${config.api_url}/moh/generate`, data);
}

export function uploadIpecs(data) {
  return axios.post(`${config.api_url}/moh/upload`, data);
}
