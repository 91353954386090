import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Confirm,
  Container,
  Dimmer,
  Header,
  Icon,
  Loader,
  Pagination,
  Popup,
  Segment,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { deleteMoh, listMoh } from "../../api/moh";
import PageLoader from "../../components/PageLoader";

function ListMoH() {
  const [page, setPage] = useState(1);

  const mohQuery = useQuery({
    queryKey: ["list-moh", page],
    queryFn: () => listMoh({ page }),
  });

  if (mohQuery.isLoading) return <PageLoader />;

  return (
    <Container style={{ margin: "20px 0px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          minHeight: "40px",
        }}
      >
        <Header as="h2">Music on Hold </Header>

        <div style={{ display: "flex" }}>
          {mohQuery.isFetching && <LoadingIndicator />}
          <Button
            basic
            as={Link}
            to={`/admin/moh/getting-to-know-you`}
            color="blue"
          >
            Company Info
          </Button>
          <Button as={Link} to={`/admin/moh/create`} color="blue">
            Create Message
          </Button>
        </div>
      </div>

      {mohQuery?.data.data.data.length > 0 ? (
        <Table basic="very" inverted fluid>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {mohQuery?.data.data.data.map((message) => {
              return (
                <TableRow>
                  <TableCell>{message.name}</TableCell>
                  <TableCell textAlign="right">
                    <Button
                      size="small"
                      as={Link}
                      to={`/admin/moh/create/${message.id}`}
                      color="blue"
                      basic
                    >
                      View
                    </Button>
                    <DeleteButton id={message.id} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div>No messages created.</div>
      )}

      {mohQuery.data.data.meta.last_page > 1 && (
        <div style={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
          <Pagination
            inverted
            activePage={page}
            totalPages={mohQuery.data.data.meta.last_page}
            onPageChange={(e, { activePage }) => setPage(activePage)}
          />
        </div>
      )}
    </Container>
  );
}

function LoadingIndicator() {
  return (
    <Segment
      basic
      style={{ margin: 0, marginLeft: "14px", marginRight: "14px" }}
    >
      <Dimmer active style={{ backgroundColor: "transparent" }}>
        <Loader size="small" />
      </Dimmer>
    </Segment>
  );
}

function DeleteButton({ id }) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: () => deleteMoh(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["list-moh"],
      });
    },
    onError: () => {
      toast.error(
        "There was problem deleting the music on hold. Please try again.",
        {
          toastId: "moh-deletion-error",
        },
      );
    },
  });

  return (
    <>
      <Popup
        position="bottom center"
        content={"Delete message"}
        basic
        trigger={
          <Button
            icon
            color="red"
            disabled={deleteMutation.isLoading}
            onClick={() => setOpen(true)}
          >
            <Icon name="delete" inverted />
          </Button>
        }
      />
      <Confirm
        open={open}
        header="Delete Music on Hold"
        content={`Really delete this music on hold message?`}
        confirmButton="Delete"
        onCancel={() => setOpen(false)}
        onConfirm={() => deleteMutation.mutate()}
      />
    </>
  );
}

export default ListMoH;
