import { useDebounce } from "@uidotdev/usehooks";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Icon,
  Input,
  Label,
} from "semantic-ui-react";
import DatePicker from "../components/datePicker/DatePicker";
import CallInfoModal from "../components/sidekick/CallInfoModal";
import CallRecordings from "../components/sidekick/CallRecordings";
import CallTranscriptions from "../components/sidekick/CallTranscriptions";
import { useSearchOptions } from "../hooks/useSearchOptions";
import sidekickLogo from "../images/sidekick-logo-75x79.png";
import { style } from "../style";

function Sidekick() {
  const [currentTab, setCurrentTab] = useState("search");
  const [infoModal, setInfoModal] = useState({
    open: false,
    id: "",
    tab: "summary",
  });

  const [searchCriteria, setSearchCriteria] = useState({});
  const [searchNumber, setSearchNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [extension, setExtension] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [userSearch, setUserSearch] = useState("");
  const debouncedUserSearch = useDebounce(userSearch, 400);
  const [searchOptions, searchOptionsIsLoading] =
    useSearchOptions(debouncedUserSearch);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchCriteria({
      tel_number: searchNumber,
      from: startDate,
      to: endDate,
      ipecs_one_ext: extension,
    });
  };

  const resetSearch = () => {
    setSearchCriteria({});
    setSearchNumber("");
    setStartDate("");
    setEndDate("");
    setExtension("");
    setUserSearch("");
  };

  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <div>
        <ButtonGroup>
          <Button
            active={currentTab === "search"}
            onClick={() => setCurrentTab("search")}
            style={
              currentTab === "search"
                ? style.gradientButton
                : style.gradientButtonInactive
            }
          >
            Search
          </Button>
          <Button
            onClick={() => setCurrentTab("processed")}
            style={
              currentTab === "processed"
                ? style.purpleGradientButton
                : style.gradientButtonInactive
            }
          >
            Processed
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ width: "100%" }}>
        <Form style={{ width: "100%" }}>
          <FormGroup inline style={{ width: "100%" }}>
            <FormField>
              <Input
                placeholder="Search by number"
                value={searchNumber}
                name="tel_number"
                onChange={(e) => setSearchNumber(e.target.value)}
                labelPosition="left"
              >
                <Label
                  image
                  style={{
                    paddingRight: "0",
                    background: "none",
                    borderTop: "1px solid #767676",
                    borderLeft: "1px solid #767676",
                    borderBottom: "1px solid #767676",
                  }}
                >
                  <img
                    src={sidekickLogo}
                    alt="Sidekick logo"
                    style={{ padding: "4px 0px 4px 10px" }}
                  />
                </Label>
                <input
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderLeft: "none",
                  }}
                />
              </Input>
            </FormField>
            <FormField>
              <DatePicker
                placeholderText="Start time"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={startDate}
                showTimeSelect
                onChange={(date) => setStartDate(date)}
              />
            </FormField>
            <FormField>
              <DatePicker
                placeholderText="End time"
                selected={endDate}
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeSelect
                onChange={(date) => setEndDate(date)}
              />
            </FormField>
            <FormField>
              <Dropdown
                placeholder="Extension"
                search
                clearable
                selection
                loading={searchOptionsIsLoading}
                disabled={searchOptionsIsLoading}
                options={searchOptions.usersDropdownList || []}
                onSearchChange={(_, { searchQuery }) =>
                  setUserSearch(searchQuery)
                }
                onChange={(_, { searchQuery, value }) => {
                  setExtension(value);
                  if (searchQuery === "") setUserSearch("");
                }}
              />
            </FormField>
            <ButtonGroup icon>
              <Button
                type="submit"
                style={style.gradientButton}
                onClick={handleSearch}
                loading={isSearching}
              >
                <Icon
                  name="search"
                  style={{
                    color: "white",
                    visibility: isSearching ? "hidden" : "visible",
                  }}
                />
              </Button>
              {(searchNumber || startDate || endDate || extension) && (
                <Button
                  color="grey"
                  style={{
                    boxShadow: style.gradientButton.boxShadow,
                    border: style.gradientButton.border,
                  }}
                  onClick={resetSearch}
                >
                  <Icon name="close" />
                </Button>
              )}
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
      {currentTab === "search" ? (
        <>
          <CallRecordings
            infoModal={infoModal}
            setInfoModal={setInfoModal}
            searchParams={searchCriteria}
            setIsSearching={setIsSearching}
          >
            <CallInfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
          </CallRecordings>
        </>
      ) : (
        <CallTranscriptions
          infoModal={infoModal}
          setInfoModal={setInfoModal}
          searchParams={searchCriteria}
          setIsSearching={setIsSearching}
        >
          <CallInfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
        </CallTranscriptions>
      )}
    </Container>
  );
}

export default Sidekick;
